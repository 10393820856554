.App {
  min-height: 100vh;
}

.App-header {
  background-color:  #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:	#F8F8FF;  /* GHOST FUCKING WHITE */
}

.App-logo {
  font-size:200px;
  color: 	#E4007C;
}

.App-notes {
  padding: 20px;
  background-color: #c0c0c0;
  min-height: 100vh;
}

.App-main {
  padding: 20px;
}

h1 {
  color: 	#303030;
}

.App-link {
  color: 	#E4007C; /* Mexican pink - love it - orig color #61dafb;*/
  text-decoration: underline;
}

.App-result {
  padding: 15px;
}
.Center-Text {
  text-align: center;
}

input.UptimeInput {
  text-align:right;
  padding-right: 2rem;
  padding: 0.5rem, 2rem;
}

/* solve for that little spinner on number inputs, don't want it */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}